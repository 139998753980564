import React, { useContext, useEffect, useState, useRef } from 'react';
import { useRouter } from 'next/router';
import { useQuery } from 'react-query';
import { gsap } from 'gsap';
import AuthContext from 'context/auth';
import { isProfileVerified } from 'utils/services/api/requests/userEnv';
import useAutoCloseBannerStatus from 'utils/customHooks/useAutoCloseBannerStatus';
import { gaAnalytics } from 'utils/functions/track/gaAnalytics';
import { useProfile } from 'utils/customHooks/useProfile';
import useTuohirullaBonusBanner from './useTuohirullaBonusBanner';
import { statusBanners } from './constants';
export default function StatusBanner() {
  const {
    query,
    replace,
    pathname
  } = useRouter();
  const {
    state,
    type,
    origin
  } = query;
  const {
    isAuthenticated,
    token
  } = useContext(AuthContext);
  const {
    data: profileValidationInfo,
    isLoading: isLoadingProfileInfo
  } = useQuery(['profile-validation-info', token], isProfileVerified, {
    enabled: !!isAuthenticated,
    staleTime: 12000
  });
  const [currentBanner, setCurrentBanner] = useState<any>(null);
  const [visible, setVisible] = useState<boolean>(false);
  const [hidden, setHidden] = useState(false);
  const [delay, setDelay] = useState(0);
  const animationsRef = useRef<gsap.core.Tween[]>([]);
  useTuohirullaBonusBanner();
  const {
    profile
  } = useProfile();
  const {
    clearTimer
  } = useAutoCloseBannerStatus({
    onAutoCloseStartCount: delayNumber => {
      setDelay(delayNumber);
      setVisible(false);
    },
    onAutoCloseEndCount() {
      setDelay(0);
      setHidden(true);
    },
    autoClear: true,
    enabled: !isLoadingProfileInfo && isAuthenticated
  });
  useEffect(() => {
    const {
      shouldGiveEmail
    } = profileValidationInfo || {};
    if (shouldGiveEmail && type !== 'collectPhoneEmail' && origin !== 'emailPicker' && pathname !== '/test') {
      sessionStorage.setItem('queryBeforeEmailPicker', JSON.stringify(query));
      setVisible(true);
      setHidden(false);
      setCurrentBanner((statusBanners as any).collectPhoneEmail);
      clearTimer();
      replace({
        pathname,
        query: {
          type: 'collectPhoneEmail'
        }
      }, undefined, {
        shallow: true
      });
    } else {
      const bannerKey: any = state ? `${type}-${state}` : type;
      if (bannerKey) {
        // skip logout banner
        if (bannerKey !== 'logout') {
          setCurrentBanner((statusBanners as any)[bannerKey]);
        }
        setVisible(true);
        setHidden(false);
        gaAnalytics.trackEvent({
          action: type as string,
          category: 'transaction',
          label: `end_${type}`,
          value: state as string,
          userId: profile?.id
        });
      } else setHidden(true);
    }
  }, [state, type, isAuthenticated, profileValidationInfo]);
  useEffect(() => {
    const container = document.getElementById('status-banner-container');
    const child = document.getElementById('status-banner-container-child');
    if (!visible && delay) {
      animationsRef.current.forEach(animation => animation.kill());
      animationsRef.current = [];
      const animationDelay = delay > 1000 ? delay / 1000 - 1 : 0;
      const containerAnimation = gsap.fromTo(container, {
        height: container?.clientHeight
      }, {
        height: 0,
        duration: 1,
        delay: animationDelay
      });
      const childAnimation = gsap.fromTo(child, {
        translateY: 0
      }, {
        translateY: '-100%',
        duration: 1,
        delay: animationDelay
      });
      animationsRef.current.push(containerAnimation, childAnimation);
    }
    if (visible) {
      if (container) {
        container.style.height = 'auto';
      }
      if (child) {
        child.style.transform = 'translateY(0)';
      }
    }
  }, [visible, delay]);
  useEffect(() => {
    if (type === 'collectPhoneEmail') {
      animationsRef.current.forEach(animation => animation.kill());
      animationsRef.current = [];
      setVisible(true);
      setHidden(false);
      setDelay(0);
    }
  }, [type]);
  if (hidden) return <></>;
  const className = 'w-full h-auto overflow-hidden';
  return <div id="status-banner-container" className={className} data-sentry-component="StatusBanner" data-sentry-source-file="index.tsx">
      <div id="status-banner-container-child" className={className}>
        {currentBanner || <></>}
      </div>
    </div>;
}