import React, { useContext } from 'react';
import { LiProgressBalance } from 'sections/liProgressBalance';
import StatusBanner from 'components/statusBanner';
import GlobalContext from 'context/global';
import { useRouter } from 'next/router';
import Grid from 'components/uiKit/grid';
import { BannerHome } from 'sections/banner';
import Navbar from 'components/navbar';
import { TopMessage } from 'components/messages/TopMessage';
import AuthContext from 'context/auth';
import HomeMobileLayout from './mobile';
import HomeDesktopLayout from './desktop';
export default function HomeLayout(props: any) {
  const {
    appType
  } = React.useContext(GlobalContext);
  const {
    isAuthenticated
  } = useContext(AuthContext);
  const {
    query
  } = useRouter();
  const {
    type
  } = query;
  return <>
      {!isAuthenticated && appType === 'mobile' && <TopMessage className="fixed z-[999]" />}
      {appType === 'mobile' && <LiProgressBalance />}
      <StatusBanner data-sentry-element="StatusBanner" data-sentry-source-file="index.tsx" />
      <Grid hidden={type === 'collectPhoneEmail'} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
        <BannerHome {...props} data-sentry-element="BannerHome" data-sentry-source-file="index.tsx" />
        <Navbar data-sentry-element="Navbar" data-sentry-source-file="index.tsx" />
        {appType === 'mobile' ? <HomeMobileLayout /> : <HomeDesktopLayout />}
      </Grid>
    </>;
}