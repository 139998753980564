import GlobalContext from 'context/global';
import { useContext } from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import AuthContext from 'context/auth';
import Grid from 'components/uiKit/grid';
import MessagesStrip from 'components/messages';
const DesktopBanner = dynamic(() => import('./desktop'));
const MobileBanner = dynamic(() => import('./mobile'));
export function BannerHome(props: any) {
  const {
    appType
  } = useContext(GlobalContext);
  const {
    isAuthenticated
  } = useContext(AuthContext);
  const {
    query
  } = useRouter();
  const {
    type
  } = query;
  const isTypeInQuery = Boolean(query?.type);
  const isMobileApp = appType === 'mobile';
  if (type || isAuthenticated) {
    return <></>;
  }
  if (isTypeInQuery) {
    return null;
  }
  return <Grid className="bg-primary" data-sentry-element="Grid" data-sentry-component="BannerHome" data-sentry-source-file="index.tsx">
      <MessagesStrip {...props} data-sentry-element="MessagesStrip" data-sentry-source-file="index.tsx" />
      {isMobileApp ? <MobileBanner /> : <DesktopBanner />}
    </Grid>;
}