import React, { ReactNode } from 'react';
import dynamic from 'next/dynamic';
import { bannerT, statusPopupI } from './types';
const LoginClosedBanner = dynamic(() => import('./variants/login/loginClosedBanner'));
const LoginFailureBanner = dynamic(() => import('./variants/login/loginFailureBanner'));
const LogoutBanner = dynamic(() => import('./variants/logout/logoutBanner'));
const LoginDepositSuccessBanner = dynamic(() => import('./variants/loginDeposit/loginDepositSuccessBanner'));
const LoginDepositClosedBanner = dynamic(() => import('./variants/loginDeposit/loginDepositClosedBanner'));
const LoginDepositFailureBanner = dynamic(() => import('./variants/loginDeposit/loginDepositFailureBanner'));
const LoginSuccessBanner = dynamic(() => import('./variants/login/loginSuccessBanner'));
const DepositClosedBanner = dynamic(() => import('./variants/deposit/depositClosedBanner'));
const DepositSuccessBanner = dynamic(() => import('./variants/deposit/depositSuccessBanner'));
const CollectPhoneEmailBanner = dynamic(() => import('./variants/collectPhoneEmail/CollectPhoneEmailBanner'));
const CollectPhoneEmailSuccessBanner = dynamic(() => import('./variants/collectPhoneEmail/CollectPhoneEmailBanner/CollectPhoneEmailSuccessBanner'));
const ConfirmEmailBanner = dynamic(() => import('./variants/collectPhoneEmail/confirmEmail'));
const WithdrawalClosedBanner = dynamic(() => import('./variants/withdraw/withdrawalClosedBanner'));
const WithdrawalSuccessBanner = dynamic(() => import('./variants/withdraw/withdrawalSuccessBanner'));
const WithdrawalFailureBanner = dynamic(() => import('./variants/withdraw/withdrawalFailureBanner'));
const WithdrawalManualApprovalBanner = dynamic(() => import('./variants/withdraw/withdrawalManualApprovalBanner'));
const WithdrawalWageringBlockedBanner = dynamic(() => import('./variants/withdraw/withdrawalWageringBlockedBanner'));
const GenericErrorBanner = dynamic(() => import('./variants/genericError'));
const TuohirullaBonusBanner = dynamic(() => import('./variants/tuohirullaBonus'));
export const statusBanners: Record<bannerT, ReactNode | statusPopupI> = {
  withdrawalManualApproval: <WithdrawalManualApprovalBanner />,
  'withdrawal-success': <WithdrawalSuccessBanner />,
  'withdrawal-failure': <WithdrawalFailureBanner />,
  'withdrawal-closed': <WithdrawalClosedBanner />,
  'withdrawal-blocked': <WithdrawalWageringBlockedBanner />,
  'withdrawal-wagering-blocked': <WithdrawalWageringBlockedBanner />,
  'login-success': <LoginSuccessBanner />,
  'login-failure': <LoginFailureBanner />,
  'login-closed': <LoginClosedBanner />,
  logout: <LogoutBanner />,
  'loginDeposit-success': <LoginDepositSuccessBanner />,
  'loginDeposit-failure': <LoginDepositFailureBanner />,
  'loginDeposit-closed': <LoginDepositClosedBanner />,
  'loginDeposit-close': <LoginDepositClosedBanner />,
  'deposit-success': <DepositSuccessBanner />,
  'deposit-failure': <LoginDepositFailureBanner />,
  'deposit-closed': <DepositClosedBanner />,
  collectPhoneEmail: <CollectPhoneEmailBanner />,
  'collectPhoneEmail-success': <CollectPhoneEmailSuccessBanner />,
  'collectPhoneEmail-failure': <CollectPhoneEmailBanner />,
  confirmEmail: <ConfirmEmailBanner />,
  'confirmEmail-success': <ConfirmEmailBanner />,
  'confirmEmail-failure': <ConfirmEmailBanner />,
  'tuohirulla-bonus': <TuohirullaBonusBanner />,
  genericError: <GenericErrorBanner />
};