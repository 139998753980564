import React, { useContext, useEffect } from 'react'
import AuthContext from 'context/auth'
import { useQuery } from 'react-query'
import { getProfile } from 'utils/services/api/requests/userEnv'
import { getTuohirullaList } from 'utils/services/api/requests/bonuses'
import { useRouter } from 'next/router'

function useTuohirullaBonusBanner() {
    const { isAuthenticated, token } = useContext(AuthContext)
    const { query, replace, pathname } = useRouter()
    const { state, type } = query

    const { data: userProfile, isLoading: isProfileLoading } = useQuery(
        ['profile', token],
        getProfile,
        { enabled: !!isAuthenticated, staleTime: 11000 },
    )
    const { login } = userProfile || {}

    const { data, isLoading: isListLoading } = useQuery(
        ['list-tuohirulla', token],
        getTuohirullaList,
        { enabled: !!isAuthenticated },
    )

    const isLoading = isProfileLoading || isListLoading

    const welcomeTuohirulla = data?.find((wheel: any) => wheel.internalName === 'TuohiRulla1')
    const tuohirullaCode = welcomeTuohirulla?.code

    const shouldDisplay = tuohirullaCode

    function displayBanner() {
        replace(
            {
                pathname,
                query: { type: 'tuohirulla', state: 'bonus', code: tuohirullaCode },
            },
            undefined,
            { shallow: true },
        )
    }

    function addBannerClosedFlag() {
        sessionStorage.setItem(`tuohirullaBannerClosed_${login}`, 'true')
    }

    function closeBanner() {
        if (login) {
            addBannerClosedFlag()
        }
        replace({ pathname }, undefined, { shallow: true })
    }

    function hasBannerBeenClosed() {
        if (login) {
            return sessionStorage.getItem(`tuohirullaBannerClosed_${login}`) === 'true'
        }
        return false
    }

    useEffect(() => {
        if (!isLoading && shouldDisplay && !type && !hasBannerBeenClosed()) {
            displayBanner()
        }
    }, [isLoading, type, state, shouldDisplay, login])

    return {
        isLoading,
        shouldDisplay,
        displayBanner,
        closeBanner,
        addBannerClosedFlag,
    }
}

export default useTuohirullaBonusBanner
