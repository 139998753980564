import { useEffect, useCallback } from 'react';
import { useRouter } from 'next/router';
let timer: any;
export interface QueryParamsHandlerProps {
  onClearCallback?: () => void;
  onAutoCloseStartCount?: (delay: number) => void;
  onAutoCloseEndCount?: () => void;
  autoClear: boolean;
  enabled: boolean;
}
const useAutoCloseBannerStatus = ({
  onAutoCloseEndCount,
  onAutoCloseStartCount,
  onClearCallback,
  autoClear,
  enabled
}: QueryParamsHandlerProps) => {
  const router = useRouter();
  const {
    query
  } = router;
  const clearQueryParams = useCallback(() => {
    if (onClearCallback) {
      onClearCallback();
    }
    const url = router.pathname;
    router.replace(url, undefined, {
      shallow: true
    });
  }, [router]);
  useEffect(() => {
    const {
      type,
      state,
      autoClose
    } = router.query;
    if ((type || state) && autoClose && enabled) {
      const autoCloseTime = parseInt(autoClose as string, 10);
      if (onAutoCloseStartCount) {
        onAutoCloseStartCount(autoCloseTime);
      }
      if (!Number.isNaN(autoCloseTime)) {
        clearTimeout(timer);
        timer = setTimeout(() => {
          if (onAutoCloseEndCount) {
            if (enabled) {
              onAutoCloseEndCount();
            }
          }
          if (autoClear) {
            clearQueryParams();
          }
        }, autoCloseTime);
        return () => clearTimeout(timer);
      }
    }
    return () => {};
  }, [query, clearQueryParams, onAutoCloseStartCount, onAutoCloseEndCount, autoClear]);
  useEffect(() => {
    if (query.type === 'collectPhoneEmail') {
      clearTimeout(timer);
    }
  }, [query.type, onAutoCloseEndCount]);
  return {
    clearTimer: () => clearTimeout(timer)
  };
};
export default useAutoCloseBannerStatus;