import { ProgressBar } from 'components/progressBar';
import Button from 'components/uiKit/buttons';
import Grid from 'components/uiKit/grid';
import UserBalanceOnlyReal from 'components/userBalance/onlyReal';
import AuthContext from 'context/auth';
import { useContext } from 'react';
import GlobalContext from 'context/global';
import useXpBonusProgress from 'utils/customHooks/useXpProgress';
import Link from 'next/link';
import Typography from 'components/uiKit/typography';
import { useRouter } from 'next/router';
import { TopMessage } from 'components/messages/TopMessage';

// ToDo: refactor
export function LiProgressBalance() {
  const {
    isAuthenticated
  } = useContext(AuthContext);
  const {
    progress,
    data,
    isLoading
  } = useXpBonusProgress();
  const {
    state
  } = useContext(GlobalContext);
  const {
    appSize
  } = state;
  const isMobile = ['xsm', 'sm'].includes(appSize);
  const {
    query
  } = useRouter();
  const {
    type
  } = query;
  if (!isMobile || !isAuthenticated || type === 'collectPhoneEmail') {
    return <></>;
  }
  return <div className="flex flex-col w-full" data-sentry-component="LiProgressBalance" data-sentry-source-file="index.tsx">
      <div className="h-[3.6rem] w-full flex">
        <div id="li-progress-balance" className="fixed top-0 left-0 right-0 w-full z-50 bg-primary h-[3.6rem]">
          <Grid verticalAlgin="center" wrap="nowrap" padding={['pt-2', 'pb-2', 'ps-3', 'pe-3']} horizontalAlgin="space-between" data-sentry-element="Grid" data-sentry-source-file="index.tsx">
            <Grid responsiveWidth={{
            sm: 25
          }} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
              <Typography translateGroup="global" translateKey={data.name} color="#fff" style={{
              letterSpacing: '0.1rem',
              fontSize: '12px',
              fontWeight: 'normal',
              whiteSpace: 'nowrap',
              paddingBottom: '2px'
            }} data-sentry-element="Typography" data-sentry-source-file="index.tsx" />
              {!isLoading && <ProgressBar progress={progress} border="secondary" />}
            </Grid>
            <Grid verticalAlgin="center" gap="0.5rem" horizontalAlgin="flex-end" data-sentry-element="Grid" data-sentry-source-file="index.tsx">
              <Grid width="max-content" horizontalAlgin="flex-end" wrap="wrap" data-sentry-element="Grid" data-sentry-source-file="index.tsx">
                <Grid verticalAlgin="flex-end" horizontalAlgin="flex-end" data-sentry-element="Grid" data-sentry-source-file="index.tsx">
                  <Typography translateGroup="global" translateKey="Balance:" color="#fff" style={{
                  letterSpacing: '0.1rem',
                  fontSize: '12px',
                  fontWeight: 'normal',
                  whiteSpace: 'nowrap'
                }} data-sentry-element="Typography" data-sentry-source-file="index.tsx" />
                </Grid>
                <Grid horizontalAlgin="flex-end" style={{
                color: '#fff',
                fontSize: '12px',
                fontWeight: 'normal'
              }} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
                  <UserBalanceOnlyReal data-sentry-element="UserBalanceOnlyReal" data-sentry-source-file="index.tsx" />
                </Grid>
              </Grid>
              <Link href="/cashier/deposit" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                <Button id="navbar-balance-cta" color="yellow" size="xsm" data-sentry-element="Button" data-sentry-source-file="index.tsx">
                  <Typography translateGroup="global" translateKey="TALLETA" style={{
                  letterSpacing: '0.1rem',
                  textTransform: 'uppercase',
                  fontSize: '14px',
                  whiteSpace: 'nowrap'
                }} weight={700} data-sentry-element="Typography" data-sentry-source-file="index.tsx" />
                </Button>
              </Link>
            </Grid>
          </Grid>
        </div>
      </div>
      <TopMessage data-sentry-element="TopMessage" data-sentry-source-file="index.tsx" />
    </div>;
}