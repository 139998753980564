import HomeLayout from 'layouts/home';
import { GetServerSidePropsContext } from 'next';
import React, { useEffect } from 'react';
import { defaultServerSideProps } from 'utils/functions/serverSide';
import { track } from 'utils/functions/track';
export default function HomePage(props: any) {
  useEffect(() => {
    track.onPageEnter('Home', {});
  }, []);
  return <HomeLayout {...props} data-sentry-element="HomeLayout" data-sentry-component="HomePage" data-sentry-source-file="index.tsx" />;
}
export async function getServerSideProps(context: GetServerSidePropsContext) {
  return defaultServerSideProps(context);
}