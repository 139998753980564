import Navbar from 'components/navbar';
import { Search } from 'components/navbar/mobile/components/search/search';
import Grid from 'components/uiKit/grid';
import { useRouter } from 'next/router';
import { ChangeEvent, useEffect, useState } from 'react';
import SearchGames from 'sections/searchGames';
import Lobby from 'components/lobby';
export default function HomeMobileLayout() {
  const {
    asPath
  } = useRouter();
  const [filter, setFilter] = useState<string>('');
  useEffect(() => {
    const url = new URL(window.location.toString());
    if (url.searchParams.has('search')) {
      const searchParam = url.searchParams.get('search') ?? '';
      setFilter(searchParam);
    } else {
      setFilter('');
    }
  }, [asPath]);
  function setCurrentSearch(search: string) {
    const url = new URL(window.location.toString());
    if (!search) {
      url.searchParams.delete('search');
    } else {
      url.searchParams.set('search', String(search));
    }
    window.history.pushState({}, '', url);
    setFilter(search);
  }
  const handleFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCurrentSearch(event.target.value);
  };
  return <Grid padding={['pt-5', 'pb-5']} data-sentry-element="Grid" data-sentry-component="HomeMobileLayout" data-sentry-source-file="index.tsx">
      <Grid horizontalAlgin="center" data-sentry-element="Grid" data-sentry-source-file="index.tsx">
        <Search handleFilterChange={handleFilterChange} filter={filter} setFilter={setFilter} data-sentry-element="Search" data-sentry-source-file="index.tsx" />
      </Grid>
      {filter.length > 0 ? <SearchGames filter={filter} color="var(--primary)" /> : <Lobby lobbyName="kasino" />}
    </Grid>;
}