import React from 'react';
import DefaultLobby from './variants/default';
interface Props {
  lobbyName: string;
}
export default function Lobby(props: Props) {
  const {
    lobbyName
  } = props;
  return <DefaultLobby lobbyName={lobbyName} data-sentry-element="DefaultLobby" data-sentry-component="Lobby" data-sentry-source-file="index.tsx" />;
}